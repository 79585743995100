<template>
  <div class="clearfix mb-0 footer-section">
    <div class="row">
      <div class="col-6 float-md-left d-block d-md-inline-block mt-25">
        COPYRIGHT © {{ new Date().getFullYear() }}
        Zircly
        <span class="d-none d-sm-inline-block">, All rights Reserved</span>
      </div>
      <div class="col-6">
        <div class="text-right">
          <b-link class="mr-25" :to="{ name: 'terms' }">Terms of use</b-link>
          |
          <b-link class="mr-25" href="#" :to="{ name: 'privacy-policy' }"
            >Privacy Policy</b-link
          >
        </div>
      </div>
      <!-- <span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
